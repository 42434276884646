import Provider from './src/lib/provider'

export const wrapRootElement = Provider

// export const onInitialClientRender = () => {
//   if (location.hostname === 'localhost') return
//
//   const script = document.createElement('script')
//   script.type = 'text/javascript'
//   script.async = true
//   script.innerHTML = `
//   window.markerConfig = {
//     project: '668c1ae409b1ec4b7328ead1',
//     source: 'snippet'
//   };
//   !function(e,r,a){if(!e.__Marker){e.__Marker={};var t=[],n={__cs:t};["show","hide","isVisible","capture","cancelCapture","unload","reload","isExtensionInstalled","setReporter","setCustomData","on","off"].forEach(function(e){n[e]=function(){var r=Array.prototype.slice.call(arguments);r.unshift(e),t.push(r)}}),e.Marker=n;var s=r.createElement("script");s.async=1,s.src="https://edge.marker.io/latest/shim.js";var i=r.getElementsByTagName("script")[0];i.parentNode.insertBefore(s,i)}}(window,document);`
//   document.head.appendChild(script)
// }

window.GlobalState = {
  isBackNavigation: false
}
//
// const handlePopState = (event) => {
//   window.GlobalState.isBackNavigation = true
// }
//
// export const onClientEntry = () => {
//   window.addEventListener('popstate', handlePopState)
// }

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (
    prevLocation &&
    location.pathname === '/our-people/' &&
    prevLocation.pathname.startsWith('/our-people/')
  ) {
    console.log('Detected navigation from any /our-people/* page to /our-people/')
    window.GlobalState.isBackNavigation = true
  } else {
    window.GlobalState.isBackNavigation = false
  }
}
